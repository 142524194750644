import { fade } from "@material-ui/core";
import {
  red,
  grey,
  lightGreen,
  blueGrey,
  green,
  orange,
} from "@material-ui/core/colors";

export const [muted1, muted2] = [grey[500], grey[300]];

export const [accent1, accent2, accent3, accent4] = [
  "#fdd000",
  grey[100],
  grey[500],
  "#e3bf16",
];
export const [primary1, primary2, primary3, primary4] = [
  "#9c0008",
  "#2f3772",
  grey[400],
  "#607d8b",
];

export const [danger1, danger2, danger3] = [
  red[500],
  red[500],
  fade(red[900], 0.87),
];
export const [success1, success2, success3] = [
  lightGreen[600],
  lightGreen[600],
  fade(lightGreen[900], 0.87),
];

export const [
  headerInfoColor,
  newButtonColor1,
  newButtonColor1Hover,
  appBarTextColor,
] = ["#b6c3ca", "#607d8b", "#8eacbb", "#fff"];

export const [
  status1,
  status2,
  status3,
  status4,
  status5,
  status6,
  status7,
  status8,
  status9,
  status10,
  status11,
  status12,
  status13,
  status14,
  status15,
  status16,
  status17,
  jobStatus1,
  jobStatus2,
  jobStatus3,
  jobStatus4,
  jobStatus5,
  jobStatus6,
  jobStatus7,
] = [
  "#4556a4",
  "#009788",
  "#fdc010",
  "#498fcd",
  "#f1592a",
  "#05bbd3",
  "#65499e",
  "#8f3f96",
  "#617e8c",
  "#7b5548",
  "#9d9d9d",
  "#f9961f",
  "#8dbf4c",
  "#ef4538",
  "#ccdb38",
  "#617e8c",
  "#e81f63",
  "#637b89",
  "#f8a422",
  "#6e2fb4",
  "#a9b637",
  "#4b8df1",
  "#cd3b35",
  "#9d9d9d",
];

export const statusColors = {
  status1,
  status2,
  status3,
  status4,
  status5,
  status6,
  status7,
  status8,
  status9,
  status10,
  status11,
  status12,
  status13,
  status14,
  status15,
  status16,
  status17,
  jobStatus1,
  jobStatus2,
  jobStatus3,
  jobStatus4,
  jobStatus5,
  jobStatus6,
  jobStatus7,
};
export const [
  button,
  icon,
  navbar,
  chip1,
  chip2,
  chip3,
  text,
  background,
  cardBackground,
] = [
  "#183E98",
  blueGrey[600],
  "#D1DAE1",
  green[500],
  orange[500],
  "#EB5757",
  blueGrey[900],
  "#F4F6F8",
  "#FFFFFF",
];

export default {
  palette: {
    primary1Color: navbar,
    primary2Color: primary2,
    primary3Color: primary3,
    primary4Color: primary4,
    buttonColor: button,
    accent1Color: button,
    accent2Color: accent2,
    accent3Color: accent3,
    accent4Color: accent4,
    activeTextColor: "#000",

    textColor: text,
    alternateTextColor: text,
    canvasColor: text,
    borderColor: grey[300],
    disabledColor: fade("#000000", 0.3),
    pickerHeaderColor: primary1,
    clockCircleColor: fade("#000000", 0.07),
    shadowColor: "#000000",
    appBarTextColor: text,
    newButtonColor1Hover,
    newButtonColor1,
    headerInfoColor,
  },
  appBar: {
    textColor: text,
  },
  tabs: {
    textColor: text,
    selectedTextColor: text,
  },
  icon: {
    color: icon,
  },
  active: {
    tab: accent1,
  },
  signIn: {
    buttonLabel: "#000000",
    buttonBackgroundColor: "#fdd000",
  },
};
